export const newPathname = ({ params, newValues }) => {
  const newParams = { ...params, ...newValues };

  const newPath = Object.keys(newParams).reduce((res, cur) => {
    if (!newParams[cur]) return res;

    return `${res}${newParams[cur]}/`;
  }, '/');

  return newPath;
};

export const newSearch = ({ params, newValues }) => {
  const newParams = { ...params, ...newValues };

  const newSearchStr = Object.keys(newParams).reduce((res, cur) => {
    if (!newParams[cur].length) {
      return res;
    }

    if (Array.isArray(newParams[cur])) {
      const list = newParams[cur].join(',');

      return res ? `${res}&${cur}=${list}` : `?${cur}=${list}`;
    }

    return res
      ? `${res}&${cur}=${String(newParams[cur])}`
      : `?${cur}=${String(newParams[cur])}`;
  }, '');

  return newSearchStr;
};

export const getAbsoluteMinfinLink = relativePath => {
  return `${process.env.APP_MINFIN_DOMAIN}${relativePath}`;
};
