export const FETCH_SEO_META_REQUEST = 'FETCH_SEO_META_REQUEST';
export const FETCH_SEO_META_SUCCESS = 'FETCH_SEO_META_SUCCESS';
export const FETCH_SEO_META_FAILURE = 'FETCH_SEO_META_FAILURE';

export const FETCH_SEO_LINKS_BLOCKS_REQUEST = 'FETCH_SEO_LINKS_BLOCKS_REQUEST';
export const FETCH_SEO_LINKS_BLOCKS_SUCCESS = 'FETCH_SEO_LINKS_BLOCKS_SUCCESS';
export const FETCH_SEO_LINKS_BLOCKS_FAILURE = 'FETCH_SEO_LINKS_BLOCKS_FAILURE';

export const FETCH_SEO_TEXTS_BLOCKS_REQUEST = 'FETCH_SEO_TEXTS_BLOCKS_REQUEST';
export const FETCH_SEO_TEXTS_BLOCKS_SUCCESS = 'FETCH_SEO_TEXTS_BLOCKS_SUCCESS';
export const FETCH_SEO_TEXTS_BLOCKS_FAILURE = 'FETCH_SEO_TEXTS_BLOCKS_FAILURE';
