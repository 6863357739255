import { createSelector } from 'reselect';

import {
  getOrganizationsList,
  makeGetOrganization,
} from 'selectors/organizations';
import {
  getFormattedRouterLocationSearch,
  getFormattedSearchParams,
} from 'selectors/router';
import { getFullPathname } from 'selectors/pathname';
import { CheckboxFilters } from 'constants/filters';

const getProducts = state => state.products;

const getSortPositionMaxToMin = (a, b) => b - a;

const getSortPositionMinToMax = (a, b) => a - b;

const getOrganization = makeGetOrganization();

export const getCurrentProducts = createSelector(
  getProducts,
  getFullPathname,
  getFormattedSearchParams,
  (state, props) => props.params,
  (products, path, { type }, { page }) => {
    if (type && !page) {
      const newProducts = products[path]?.entities?.filter(el =>
        el.productClientType.includes(type[0])
      );

      const newIds = newProducts?.map(el => el.id);

      return { ...products[path], entities: newProducts, ids: newIds } || {};
    }

    return products[path] || {};
  }
);

export const getProductsIsFetching = createSelector(
  getCurrentProducts,
  products => products?.isFetching
);

export const getProductsIsLoaded = createSelector(
  getCurrentProducts,
  products => products?.isLoaded
);

export const getProductsIsRequestFailed = createSelector(
  getCurrentProducts,
  products => products?.isRequestFailed
);

export const getProductsIds = createSelector(
  getCurrentProducts,
  products => products.ids || []
);

export const getProductsEntities = createSelector(
  getCurrentProducts,
  products => products.entities || []
);

export const getProductsItems = createSelector(
  getProductsIds,
  getProductsEntities,
  (ids, entities) => {
    const productsItems = ids?.flat().reduce((result, id) => {
      const indexOfEntities = entities?.map(el => el.id).indexOf(id);

      if (indexOfEntities !== -1) {
        result.push(entities[indexOfEntities]);
      }

      return result;
    }, []);

    return productsItems;
  }
);

export const getCompanyWithProducts = createSelector(
  getProductsItems,
  getOrganization,
  (products, organization) => {
    return { ...organization.data, products };
  }
);

const getFilterProductsBySearchParams = (
  routerLocationSearch,
  productItems
) => {
  if (!Object.values(routerLocationSearch).filter(el => el?.length)?.length) {
    return productItems;
  }

  const formatedFilters = Object.keys(routerLocationSearch).reduce(
    (res, cur) => {
      return [
        ...res,
        ...routerLocationSearch[cur]?.map(el => {
          return { [cur]: el };
        }),
      ];
    },
    []
  );

  const filterByCheckboxes = formatedFilters.reduce((res, cur) => {
    const key = Object.keys(cur)[0];
    const value = cur[key];
    return CheckboxFilters[key][value]?.getFiltred(res);
  }, productItems);

  return filterByCheckboxes;
};

const getProductsGroupByOrganizations = products => {
  return products.reduce((res, product) => {
    if (!product.vendorId) return res;

    res[product.vendorId] = res[product.vendorId]
      ? [...res[product.vendorId], product]
      : [product];

    return res;
  }, {});
};

const getOrganizationById = (id, organizations) => {
  return organizations.find(org => org.id === id);
};

const getOrganizationsWithProducts = (
  productsByOrganizations,
  organizations
) => {
  return Object.keys(productsByOrganizations).reduce((res, organizationId) => {
    const organizationById = getOrganizationById(
      +organizationId,
      organizations
    );

    if (!organizationById) {
      return res;
    }

    const organizationWithProducts = {
      ...organizationById,
      products: productsByOrganizations[organizationId],
    };

    return [...res, organizationWithProducts];
  }, []);
};

const isArrHasUniqueValue = arr => {
  return new Set(arr).size > 1;
};

const getDefaultSortProducts = (currentCards, organization) => {
  if (isArrHasUniqueValue(currentCards?.map(card => card.trackingUrl))) {
    return {
      ...organization,
      products: currentCards?.sort((a, b) =>
        getSortPositionMinToMax(a.trackingUrl, b.trackingUrl)
      ),
    };
  }

  if (isArrHasUniqueValue(currentCards?.map(card => card.monthlyFee))) {
    return {
      ...organization,
      products: currentCards?.sort((a, b) =>
        getSortPositionMinToMax(a.monthlyFee, b.monthlyFee)
      ),
    };
  }

  if (
    isArrHasUniqueValue(currentCards?.map(card => card.firstAccountOpeningFee))
  ) {
    return {
      ...organization,
      products: currentCards?.sort((a, b) =>
        getSortPositionMinToMax(
          a.firstAccountOpeningFee,
          b.firstAccountOpeningFee
        )
      ),
    };
  }

  return {
    ...organization,
    products: currentCards?.sort((a, b) => getSortPositionMaxToMin(a.id, b.id)),
  };
};

const getDefaultSortOrganizationsByProducts = data => {
  const getCurrentProduct = org => org?.products?.[0];
  return data.sort((a, b) => {
    const aProduct = getCurrentProduct(a);
    const bProduct = getCurrentProduct(b);

    if (aProduct?.monthlyFee !== bProduct?.monthlyFee) {
      return getSortPositionMinToMax(aProduct.monthlyFee, bProduct.monthlyFee);
    }

    return getSortPositionMinToMax(
      aProduct.paymentAbsolute,
      bProduct.paymentAbsolute
    );
  });
};

const getSortedOrganizationsAndProducts = productsByOrganization => {
  return getDefaultSortOrganizationsByProducts(
    productsByOrganization.reduce((res, cur) => {
      const organizationWithSortedProducts = getDefaultSortProducts(
        cur.products,
        cur
      );

      return [...res, organizationWithSortedProducts];
    }, [])
  ).reduce((res, cur) => {
    if (cur?.rkoCommercialOrderMinfin !== 0) {
      const prev = res.slice(0, cur.rkoCommercialOrderMinfin - 1);
      const next = res.slice(cur.rkoCommercialOrderMinfin - 1);

      return [...prev, cur, ...next];
    }

    return [...res, cur];
  }, []);
};

export const getCompaniesWithProducts = createSelector(
  getProductsItems,
  getOrganizationsList,
  getFormattedRouterLocationSearch,
  (products, organizations, routerLocationSearch) => {
    const filtredProducts = getFilterProductsBySearchParams(
      routerLocationSearch,
      products
    );

    const productsGroupedByOrganizations = getProductsGroupByOrganizations(
      filtredProducts
    );

    const organizationsWithProducts = getOrganizationsWithProducts(
      productsGroupedByOrganizations,
      organizations
    );

    const sortedOrganizationsWithProducts = getSortedOrganizationsAndProducts(
      organizationsWithProducts,
      routerLocationSearch
    );

    return sortedOrganizationsWithProducts;
  }
);
