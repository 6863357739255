import { combineReducers } from 'redux';

import seo from './seo';
import organizations from './organizations';
import products from './products';
import currencies from './currencies';
import updatedDate from './updatedDate';

const rootReducer = combineReducers({
  seo,
  organizations,
  updatedDate,
  products,
  currencies,
});

export default rootReducer;
