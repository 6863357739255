export const colorWhite = '#fff';
export const colorWhite2 = '#f2f5f8';
export const colorWhite3 = '#fefbf3';
export const colorBlack = '#000';
export const colorBlack900 = '#111512';
export const colorBlack500 = '#1E2621';
export const colorBlack300 = '#454D49';
export const colorBlack200 = '#696F6B';
export const colorGrey = '#575757';
export const colorGreyDark = '#2d2d2d';
export const colorGreyLight = '#93a4ac';
export const colorGrey40 = '#E6E6E6';
export const colorGrey50 = '#F2F3F2';
export const colorGrey100 = '#E5E5E5';
export const colorGrey200 = '#CACCCB';
export const colorGrey300 = '#A8B0AC';
export const colorGrey400 = '#87928C';
export const colorGrey700 = '#2B2B2B';
export const colorGrey900 = '#252525';
export const colorRed = '#df3014';
export const colorRedLight = '#f5c1b8';
export const colorOrange = '#ff7500';
export const colorYellow = '#fac917';
export const colorGreen400 = '#35A47C';
export const green50 = '#298662';
export const primary400 = '#35A47C';
export const colorGreen50 = '#F3FAF7';
export const colorBlue = '#244696';
export const colorBlue10 = '#5076C0';
export const colorBlueLight = '#cbdaf4';
