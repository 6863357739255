import { request } from 'utils';

import {
  FETCH_SEO_META_REQUEST,
  FETCH_SEO_META_SUCCESS,
  FETCH_SEO_META_FAILURE,
  FETCH_SEO_LINKS_BLOCKS_REQUEST,
  FETCH_SEO_LINKS_BLOCKS_SUCCESS,
  FETCH_SEO_LINKS_BLOCKS_FAILURE,
  FETCH_SEO_TEXTS_BLOCKS_REQUEST,
  FETCH_SEO_TEXTS_BLOCKS_SUCCESS,
  FETCH_SEO_TEXTS_BLOCKS_FAILURE,
} from 'actionTypes';

import {
  makeGetSeoUrl,
  makeGetSeoMeta,
  makeGetSeoLinksBlocks,
  makeGetSeoTextsBlocks,
} from 'selectors';

const getSeoUrl = makeGetSeoUrl();
const getSeoMeta = makeGetSeoMeta();
const getSeoLinksBlocks = makeGetSeoLinksBlocks();
const getSeoTextsBlocks = makeGetSeoTextsBlocks();

const getShouldFetchSeoMeta = (state, props) => {
  const { isFetching, isLoaded } = getSeoMeta(state, props);

  return !(isFetching || isLoaded);
};

export const fetchSeoMeta = props => async (dispatch, getState) => {
  const state = getState();

  if (!getShouldFetchSeoMeta(state, props)) {
    return true;
  }

  const url = getSeoUrl(state, props);

  dispatch({
    type: FETCH_SEO_META_REQUEST,
    payload: { url },
  });

  const requestURL = `${process.env.APP_SEO_META_API_ROOT}?url=${url}`;

  try {
    const {
      data: {
        title: metaTitle,
        description: metaDescription,
        h1: pageTitle,
        h2: pageDescription,
      },
    } = await request(requestURL);

    return dispatch({
      type: FETCH_SEO_META_SUCCESS,
      payload: {
        url,
        metaTitle,
        metaDescription,
        pageTitle,
        pageDescription,
      },
    });
  } catch (error) {
    return dispatch({
      type: FETCH_SEO_META_FAILURE,
      payload: { url },
    });
  }
};

const getShouldFetchSeoLinksBlocks = (state, props) => {
  const { isFetching, isLoaded } = getSeoLinksBlocks(state, props);

  return !(isFetching || isLoaded);
};

export const fetchSeoLinksBlocks = props => async (dispatch, getState) => {
  const state = getState();

  if (!getShouldFetchSeoLinksBlocks(state, props)) {
    return true;
  }

  const url = getSeoUrl(state, props);

  dispatch({
    type: FETCH_SEO_LINKS_BLOCKS_REQUEST,
    payload: { url },
  });

  const requestURL = `${process.env.APP_SEO_LINKS_BLOCKS_API_ROOT}?url=${process.env.APP_ROUTER_BASENAME}${props.location.pathname}&locale=${props.intl.locale}`;

  try {
    const response = await request(requestURL);

    return dispatch({
      type: FETCH_SEO_LINKS_BLOCKS_SUCCESS,
      payload: {
        url,
        items: response.data,
      },
    });
  } catch (error) {
    return dispatch({
      type: FETCH_SEO_LINKS_BLOCKS_FAILURE,
      payload: { url },
    });
  }
};

const getShouldFetchSeoTexts = (state, props) => {
  const { isFetching, isLoaded } = getSeoTextsBlocks(state, props);

  return !(isFetching || isLoaded);
};

const fetchSeoTextsBlocksMenu = async (data, locale) => {
  if (!data.length) {
    return [];
  }

  try {
    const { id } = data[0];

    const requestUrl = `${process.env.APP_SEO_TEXTS_BLOCKS_MENU_API_ROOT}?id=${id}&locale=${locale}`;
    const menu = await request(requestUrl);

    return menu;
  } catch (error) {
    return [];
  }
};

export const fetchSeoTextsBlocks = props => async (dispatch, getState) => {
  const state = getState();

  if (!getShouldFetchSeoTexts(state, props)) {
    return true;
  }

  const url = getSeoUrl(state, props);

  dispatch({
    type: FETCH_SEO_TEXTS_BLOCKS_REQUEST,
    payload: { url },
  });

  const requestURL = `${
    process.env.APP_SEO_TEXTS_BLOCKS_API_ROOT
  }?url=${encodeURIComponent(
    `${process.env.APP_MINFIN_DOMAIN}${process.env.APP_ROUTER_BASENAME}${props.location.pathname}`
  )}&locale=${props.intl.locale}`;

  try {
    const response = await request(requestURL);
    const menu = await fetchSeoTextsBlocksMenu(response, props.intl.locale);

    return dispatch({
      type: FETCH_SEO_TEXTS_BLOCKS_SUCCESS,
      payload: {
        url,
        items: response,
        menu,
      },
    });
  } catch (error) {
    return dispatch({
      type: FETCH_SEO_TEXTS_BLOCKS_FAILURE,
      payload: { url },
    });
  }
};
