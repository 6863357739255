import { schema } from 'normalizr';

// --------- GET DATA FOR ORGANIZATIONS --------- //

export const organization = new schema.Entity(
  'organizations',
  {},
  {
    idAttribute: ({ id }) => parseInt(id, 10),
    processStrategy: ({
      id,
      financeId,
      name,
      nameG,
      slug,
      license,
      vendorLink,
      ratingStability,
      popular,
      logo,
      logoSquare1x,
      fullLogo,
      menu,
      backgroundImage,
      rkoCommercialOrderMinfin,
    }) => ({
      id: parseInt(financeId, 10) || 0,
      minfinId: parseInt(id, 10) || 0,
      slug: slug.toLowerCase(),
      ratingStability: Number(ratingStability),
      name,
      nameG,
      license,
      popular,
      vendorLink,
      logo,
      logoSquare1x,
      fullLogo,
      menu,
      backgroundImage,
      rkoCommercialOrderMinfin: rkoCommercialOrderMinfin || 0,
    }),
  }
);

export const organizations = [organization];
