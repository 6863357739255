import { createSelector } from 'reselect';

export const getOrganizations = state => state.organizations;
export const getOrganizationsIds = state => state.organizations.ids;
export const getOrganization = state => state.organizations.organization;
export const getOrganizationsList = state =>
  Object.values(state.organizations.entities);

export const getOrganizationsIsFetching = createSelector(
  getOrganizations,
  organizations => organizations?.isFetching
);

export const getOrganizationsIsLoaded = createSelector(
  getOrganizations,
  organizations => organizations?.isLoaded
);

export const getOrganizationsIsRequestFailed = createSelector(
  getOrganizations,
  organizations => organizations?.isRequestFailed
);

export const makeGetOrganization = () => {
  return createSelector(
    getOrganization,
    (state, props) => props.params,
    (organization, { organizationSlug }) =>
      (!!organization && organization[organizationSlug]) || {}
  );
};

export const getOrganizationIsFetching = createSelector(
  makeGetOrganization(),
  organization => organization?.isFetching
);

export const getOrganizationIsLoaded = createSelector(
  makeGetOrganization(),
  organization => organization?.isLoaded
);

export const getOrganizationIsRequestFailed = createSelector(
  makeGetOrganization(),
  organization => organization?.isRequestFailed
);
