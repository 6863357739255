import {
  FETCH_UPDATE_DATE_REQUEST,
  FETCH_UPDATE_DATE_SUCCESS,
  FETCH_UPDATE_DATE_FAILURE,
} from 'actionTypes';

import createReducer from './createReducer';

const initialState = {
  isFetching: false,
  isRequestFailed: false,
  isLoaded: false,
};

const updatedDate = createReducer(initialState, {
  [FETCH_UPDATE_DATE_REQUEST]: state => ({
    ...state,
    isFetching: true,
    isRequestFailed: false,
    isLoaded: false,
  }),

  [FETCH_UPDATE_DATE_SUCCESS]: (state, action) => ({
    ...state,
    ...action.payload,
    isFetching: false,
    isLoaded: true,
  }),

  [FETCH_UPDATE_DATE_FAILURE]: state => ({
    ...state,
    isFetching: false,
    isRequestFailed: true,
  }),
});

export default updatedDate;
