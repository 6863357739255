import { normalize } from 'normalizr';

import { request } from 'utils';

import {
  FETCH_PRODUCTS_REQUEST,
  FETCH_PRODUCTS_FAILURE,
  FETCH_PRODUCTS_SUCCESS,
} from 'actionTypes';

import { PRODUCT_TYPE } from 'constants/filters';

import {
  getProductsIsFetching,
  getProductsIsLoaded,
  routerStateValuesConfig,
  getFormattedRouterUrlState,
  getFullPathname,
  getFormattedSearchParams,
} from 'selectors';

import { products as productsSchema } from 'schemas';

const fetchIds = async (productType, filters) => {
  const url = `${process.env.APP_PRODUCTS_API_ROOT}list?product_type=${productType}&active=1${filters}`;

  const { data } = await request(url);

  return data;
};

const fetchProductsIds = async (state, props) => {
  const routerUrlState = getFormattedRouterUrlState(state, props);
  const searchParams = getFormattedSearchParams(state, props);

  const filters = Object.keys(routerStateValuesConfig).reduce((res, cur) => {
    // Excluding request parameters from routerStateValuesConfig:
    if (routerStateValuesConfig[cur].type === 'checkbox') {
      return res;
    }

    const { name, getProductsAPIFilters } = routerStateValuesConfig[cur];

    const filter = getProductsAPIFilters(routerUrlState[name], {
      organization:
        state?.organizations?.organization[props.params.organizationSlug]?.data,
      page: props.params.page,
      clientType: props.params.clientType,
      currency: searchParams.currency?.[0],
      monthlyFee: searchParams.monthlyFee,
      activityType: searchParams.activityType,
    });

    return `${res}${filter ? `&${filter}` : ''}`;
  }, '');

  const ids = await fetchIds(PRODUCT_TYPE.rko, filters);

  return ids;
};

const getShouldFetchProducts = (state, props) => {
  const isFetching = getProductsIsFetching(state, props);
  const isLoaded = getProductsIsLoaded(state, props);

  return !isFetching && !isLoaded;
};

export const fetchProducts = props => async (dispatch, getState) => {
  try {
    const state = getState();

    if (!getShouldFetchProducts(state, props)) {
      return true;
    }

    const url = getFullPathname(state, props);

    dispatch({
      type: FETCH_PRODUCTS_REQUEST,
      payload: { url },
    });

    const produtsIds = await fetchProductsIds(state, props);

    const fetchUrl = `${process.env.APP_PRODUCTS_API_ROOT}data?locale=${
      props.intl.locale
    }&ids=${produtsIds.join(',')}`;

    const { data } = await request(fetchUrl);

    const {
      entities: { products: entities = {} },
      result: ids = [],
    } = normalize(data, productsSchema);

    return dispatch({
      type: FETCH_PRODUCTS_SUCCESS,
      payload: {
        url,
        entities: Object.values(entities),
        ids,
      },
    });
  } catch (error) {
    const state = getState();

    const url = getFullPathname(state, props);

    return dispatch({
      type: FETCH_PRODUCTS_FAILURE,
      payload: { url },
    });
  }
};

export const fetchProductsByFilters = props => async (dispatch, getState) => {
  try {
    const state = getState();

    const url = getFullPathname(state, props);

    dispatch({
      type: FETCH_PRODUCTS_REQUEST,
      payload: { url, isFilterFetching: true },
    });

    const ids = await fetchProductsIds(state, props);

    const fetchUrl = `${process.env.APP_PRODUCTS_API_ROOT}data?locale=${
      props.intl.locale
    }&ids=${ids.join(',')}`;

    const { data } = await request(fetchUrl);

    return dispatch({
      type: FETCH_PRODUCTS_SUCCESS,
      payload: {
        url,
        entities: data,
        ids: ids || [],
        isFilterFetching: false,
      },
    });
  } catch (error) {
    const state = getState();

    const url = getFullPathname(state, props);

    return dispatch({
      type: FETCH_PRODUCTS_FAILURE,
      payload: { url },
    });
  }
};
