export const META_FOR_RKO_PAGE = {
  metaTitleID: 'homePage.metaTitle',
  metaDescriptionID: 'homePage.metaDescription',
  titleID: 'homePage.title',
  descriptionID: 'homePage.description',
};

export const META_FOR_FOP_PAGE = {
  metaTitleID: 'fopPage.metaTitle',
  metaDescriptionID: 'fopPage.metaDescription',
  titleID: 'fopPage.title',
  descriptionID: 'fopPage.description',
};

export const META_FOR_IT_PAGE = {
  metaTitleID: 'itPage.metaTitle',
  metaDescriptionID: 'itPage.metaDescription',
  titleID: 'itPage.title',
  descriptionID: 'itPage.description',
};

export const META_FOR_RVK_PAGE = {
  metaTitleID: 'rvkPage.metaTitle',
  metaDescriptionID: 'rvkPage.metaDescription',
  titleID: 'rvkPage.title',
  descriptionID: 'rvkPage.description',
};
