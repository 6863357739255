import React, { memo } from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { getLocaleUrlPath } from 'utils';
import { LOCALE } from 'constants/index';

const APP_META_TAGS = [
  process.env.APP_DISABLE_CRAWLING === 'true'
    ? { name: 'robots', content: 'noindex, nofollow' }
    : {},
];

const APP_BODY_ATTRIBUTES = {};

const AppHelmet = memo(({ location }) => {
  const { locale } = useIntl();

  return (
    <Helmet
      htmlAttributes={{ lang: locale }}
      meta={APP_META_TAGS}
      link={[
        {
          rel: 'alternate',
          hreflang: LOCALE.uk,
          href: `${process.env.APP_MINFIN_DOMAIN}/ua${process.env.APP_ROUTER_BASENAME}${location.pathname}${location.search}`,
        },
        {
          rel: 'alternate',
          hreflang: LOCALE.ru,
          href: `${process.env.APP_MINFIN_DOMAIN}${process.env.APP_ROUTER_BASENAME}${location.pathname}${location.search}`,
        },
        {
          rel: 'canonical',
          href: `${process.env.APP_MINFIN_DOMAIN}${getLocaleUrlPath(locale)}${
            process.env.APP_ROUTER_BASENAME
          }${location.pathname}`,
        },
      ]}
      bodyAttributes={APP_BODY_ATTRIBUTES}
    />
  );
});

AppHelmet.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(AppHelmet);
