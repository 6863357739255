import {
  FETCH_FINANCE_ORGANIZATIONS_REQUEST,
  FETCH_FINANCE_ORGANIZATIONS_SUCCESS,
  FETCH_FINANCE_ORGANIZATIONS_FAILURE,
  FETCH_FINANCE_ORGANIZATION_REQUEST,
  FETCH_FINANCE_ORGANIZATION_SUCCESS,
  FETCH_FINANCE_ORGANIZATION_FAILURE,
} from 'actionTypes';

import createReducer from './createReducer';

const initialState = {
  entities: [],
  ids: [],
  organization: {},
  isFetching: false,
  isRequestFailed: false,
  isLoaded: false,
};

const ORGANIZATIONS = createReducer(initialState, {
  [FETCH_FINANCE_ORGANIZATIONS_REQUEST]: state => ({
    ...state,
    isFetching: true,
    isRequestFailed: false,
    isLoaded: false,
  }),

  [FETCH_FINANCE_ORGANIZATIONS_SUCCESS]: (state, action) => ({
    ...state,
    entities: action.payload.entities,
    ids: action.payload.ids,
    isFetching: false,
    isLoaded: true,
  }),

  [FETCH_FINANCE_ORGANIZATIONS_FAILURE]: state => ({
    ...state,
    isFetching: false,
    isRequestFailed: true,
  }),

  [FETCH_FINANCE_ORGANIZATION_REQUEST]: (state, action) => ({
    ...state,
    organization: {
      ...state.organization,
      [action.payload.organizationSlug]: {
        ...action.payload,
        isFetching: true,
        isRequestFailed: false,
        isLoaded: false,
      },
    },
  }),

  [FETCH_FINANCE_ORGANIZATION_SUCCESS]: (state, action) => ({
    ...state,
    organization: {
      ...state.organization,
      [action.payload.organizationSlug]: {
        data: action.payload.data,
        isFetching: false,
        isRequestFailed: false,
        isLoaded: true,
      },
    },
  }),

  [FETCH_FINANCE_ORGANIZATION_FAILURE]: (state, action) => ({
    ...state,
    organization: {
      ...state.organization,
      [action.payload.organizationSlug]: {
        ...action.payload,
        isFetching: false,
        isRequestFailed: true,
        isLoaded: false,
      },
    },
  }),
});

export default ORGANIZATIONS;
