import {
  CURRENCIES,
  CURRENCIES_SYMBOLS,
  LINK,
  MORE_INFO_NAMES,
} from 'constants/index';

export const isNumber = n => {
  return Number(n) === n;
};

const formatCurrency = currency => {
  if (CURRENCIES[currency.toLowerCase()]) {
    return CURRENCIES_SYMBOLS[currency.toLowerCase()];
  }

  return currency;
};

export const getFormattedPayments = (
  formatMessage,
  percent,
  absolute,
  min,
  max = null,
  currency = 'UAH'
) => {
  const formattedCurrency = Array.isArray(currency)
    ? (currency.length && currency.join('/')) || 'UAH'
    : formatCurrency(currency);

  const formattedPercent = isNumber(percent) && !!percent ? `${percent}%` : '';

  const formattedAbsolute =
    isNumber(absolute) && absolute ? `${absolute} ${formattedCurrency}` : '';

  const formattedMin =
    isNumber(min) && min
      ? `(${formatMessage({ id: 'min' })} ${min} ${formattedCurrency})`
      : '';

  const formattedMax =
    isNumber(max) && max
      ? `(${formatMessage({ id: 'max' })} ${max} ${formattedCurrency})`
      : '';

  const formattedPayments =
    [formattedPercent, formattedAbsolute, formattedMin, formattedMax]
      .filter(el => !!el)
      .join(' ') || formatMessage({ id: 'MenuItems.params.free' });

  return formattedPayments;
};

export const getMenu = formatMessage => {
  const menu = [
    {
      name: formatMessage({ id: 'MenuItems.general' }),
      isActive: true,
      getParams: ({
        productClientType,
        productClientActivityType,
        firstAccountOpeningFee,
        addAccountOpeningFee,
        productAccountOpeningRemote,
        productAccountOpeningDescription,
        monthlyFeeDescription,
        monthlyFee,
        annualFee,
        accountClosingFee,
        currency,
        additionalCurrencies,
      }) => {
        const prodAccOpeningDescr =
          productAccountOpeningDescription ||
          formatMessage({ id: 'MenuItems.params.possible' });

        const prodAccOpeningRemote = productAccountOpeningRemote
          ? prodAccOpeningDescr
          : formatMessage({ id: 'MenuItems.params.impossible' });

        const feeDescr =
          monthlyFee || annualFee
            ? [
                monthlyFee &&
                  `${monthlyFee} ${formatMessage(
                    {
                      id: 'MenuItems.params.monthlyFee',
                    },
                    { currency: 'UAH' }
                  )}`,
                annualFee &&
                  `${annualFee} ${formatMessage(
                    {
                      id: 'MenuItems.params.annualFee',
                    },
                    { currency: 'UAH' }
                  )}`,
              ]
                .filter(el => !!el)
                .join(formatMessage({ id: 'MenuItems.params.or' }))
            : formatMessage({ id: 'MenuItems.params.free' });

        return {
          productClientType: productClientType
            .map(el =>
              formatMessage({
                id: `BankCardItem.productClientType.${el.toLowerCase()}`,
              })
            )
            .join(', '),
          productClientActivityType: productClientActivityType.map(el =>
            formatMessage({
              id: `MoreFilters.multiSelect.activityType.${el}`,
            })
          ),
          accountOpening: `${firstAccountOpeningFee} ${formatMessage({
            id: 'MenuItems.params.UAH',
          })}`,
          addAccount: `${addAccountOpeningFee} ${formatMessage({
            id: 'MenuItems.params.UAH',
          })}`,
          accountOpeningRemote: prodAccOpeningRemote,
          fee: monthlyFeeDescription || feeDescr,
          accountClosing: accountClosingFee
            ? `${accountClosingFee} ${formatMessage({
                id: 'MenuItems.params.UAH',
              })}`
            : formatMessage({
                id: 'MenuItems.params.free',
              }),
          availableCurrencies: [currency, ...additionalCurrencies].join(', '),
        };
      },
    },
    {
      name: formatMessage({ id: 'MenuItems.payments' }),
      isActive: false,
      getParams: ({
        paymentInternalDescription,
        paymentInternalPercent,
        paymentInternalAbsolute,
        paymentInternalMin,
        paymentSelfDescription,
        paymentSelfPercent,
        selfPaymentSelfAbsolute,
        paymentSelfMin,
        paymentDescription,
        paymentPercent,
        paymentAbsolute,
        paymentMin,
        paymentAdditionalFee,
      }) => {
        return {
          paymentInternal:
            paymentInternalDescription ||
            getFormattedPayments(
              formatMessage,
              paymentInternalPercent,
              paymentInternalAbsolute,
              paymentInternalMin
            ),
          paymentSelf:
            paymentSelfDescription ||
            getFormattedPayments(
              formatMessage,
              paymentSelfPercent,
              selfPaymentSelfAbsolute,
              paymentSelfMin
            ),
          payment:
            paymentDescription ||
            getFormattedPayments(
              formatMessage,
              paymentPercent,
              paymentAbsolute,
              paymentMin
            ),
          ...(paymentAdditionalFee
            ? { additionalFee: paymentAdditionalFee }
            : {}),
        };
      },
    },
    {
      name: formatMessage({ id: 'MenuItems.receivingPayments' }),
      isActive: false,
      getParams: ({
        enrollmentCashDescription,
        enrollmentCashPercent,
        enrollmentCashAbsolute,
        enrollmentCashMin,
        enrollmentCashMax,
        enrollmentCardDescription,
        enrollmentCardPercent,
        enrollmentCardAbsolute,
        enrollmentCardMin,
        enrollmentCardMax,
        enrollmentCashlessDescription,
        enrollmentCashlessPercent,
        enrollmentCashlessAbsolute,
        enrollmentCashlessMin,
        enrollmentCashlessMax,
        internetAcquiringAvailable,
        enrollmentInternetAcquiringDescription,
        enrollmentInternetAcquiringPercent,
        enrollmentInternetAcquiringAbsolute,
        enrollmentInternetAcquiringMin,
        enrollmentInternetAcquiringMax,
        tradeAcquiringAvailable,
        enrollmentTradeAcquiringDescription,
        enrollmentTradeAcquiringPercent,
        enrollmentTradeAcquiringAbsolute,
        enrollmentTradeAcquiringMin,
        enrollmentTradeAcquiringMax,
      }) => {
        return {
          enrollmentCash:
            enrollmentCashDescription ||
            getFormattedPayments(
              formatMessage,
              enrollmentCashPercent,
              enrollmentCashAbsolute,
              enrollmentCashMin,
              enrollmentCashMax
            ),
          enrollmentCard:
            enrollmentCardDescription ||
            getFormattedPayments(
              formatMessage,
              enrollmentCardPercent,
              enrollmentCardAbsolute,
              enrollmentCardMin,
              enrollmentCardMax
            ),
          enrollmentCashless:
            enrollmentCashlessDescription ||
            getFormattedPayments(
              formatMessage,
              enrollmentCashlessPercent,
              enrollmentCashlessAbsolute,
              enrollmentCashlessMin,
              enrollmentCashlessMax
            ),
          internetAcquiring: internetAcquiringAvailable
            ? enrollmentInternetAcquiringDescription ||
              getFormattedPayments(
                formatMessage,
                enrollmentInternetAcquiringPercent,
                enrollmentInternetAcquiringAbsolute,
                enrollmentInternetAcquiringMin,
                enrollmentInternetAcquiringMax
              )
            : formatMessage({ id: 'MenuItems.params.serviceUnavailable' }),
          tradeAcquiring: tradeAcquiringAvailable
            ? enrollmentTradeAcquiringDescription ||
              getFormattedPayments(
                formatMessage,
                enrollmentTradeAcquiringPercent,
                enrollmentTradeAcquiringAbsolute,
                enrollmentTradeAcquiringMin,
                enrollmentTradeAcquiringMax
              )
            : formatMessage({ id: 'MenuItems.params.serviceUnavailable' }),
        };
      },
    },
    {
      name: formatMessage({ id: 'MenuItems.cashDeposit' }),
      isActive: false,
      getParams: ({
        proceedsEnrollmentCashboxAvailable,
        proceedsEnrollmentCashboxDescription,
        proceedsEnrollmentCashboxPercent,
        proceedsEnrollmentCashboxAbsolute,
        proceedsEnrollmentCashboxMin,
        proceedsEnrollmentTerminalAvailable,
        proceedsEnrollmentTerminalDescription,
        proceedsEnrollmentTerminalPercent,
        proceedsEnrollmentTerminalAbsolute,
        proceedsEnrollmentTerminalMin,
      }) => {
        return {
          proceedsEnrollmentCashbox: proceedsEnrollmentCashboxAvailable
            ? proceedsEnrollmentCashboxDescription ||
              getFormattedPayments(
                formatMessage,
                proceedsEnrollmentCashboxPercent,
                proceedsEnrollmentCashboxAbsolute,
                proceedsEnrollmentCashboxMin
              )
            : formatMessage({ id: 'MenuItems.params.serviceUnavailable' }),
          proceedsEnrollmentTerminal: proceedsEnrollmentTerminalAvailable
            ? proceedsEnrollmentTerminalDescription ||
              getFormattedPayments(
                formatMessage,
                proceedsEnrollmentTerminalPercent,
                proceedsEnrollmentTerminalAbsolute,
                proceedsEnrollmentTerminalMin
              )
            : formatMessage({ id: 'MenuItems.params.serviceUnavailable' }),
        };
      },
    },
    {
      name: formatMessage({ id: 'MenuItems.cashWithdrawal' }),
      isActive: false,
      getParams: ({
        withdrawalCashboxDescription,
        withdrawalCashboxPercent,
        withdrawalCashboxAbsolute,
        withdrawalCashboxMin,
        withdrawalForeignCashboxAvailable,
        withdrawalForeignCashboxDescription,
        withdrawalForeignCashboxPercent,
        withdrawalForeignCashboxAbsolute,
        withdrawalForeignCashboxMin,
        withdrawalAtmAvailable,
        withdrawalAtmDescription,
        withdrawalAtmPercent,
        withdrawalAtmAbsolute,
        withdrawalAtmMin,
        withdrawalForeignAtmAvailable,
        withdrawalForeignAtmDescription,
        withdrawalForeignAtmPercent,
        withdrawalForeignAtmAbsolute,
        withdrawalForeignAtmMin,
      }) => {
        return {
          withdrawalCashbox:
            withdrawalCashboxDescription ||
            getFormattedPayments(
              formatMessage,
              withdrawalCashboxPercent,
              withdrawalCashboxAbsolute,
              withdrawalCashboxMin
            ),
          withdrawalForeignCashbox: withdrawalForeignCashboxAvailable
            ? withdrawalForeignCashboxDescription ||
              getFormattedPayments(
                formatMessage,
                withdrawalForeignCashboxPercent,
                withdrawalForeignCashboxAbsolute,
                withdrawalForeignCashboxMin
              )
            : formatMessage({ id: 'MenuItems.params.serviceUnavailable' }),
          withdrawalAtm: withdrawalAtmAvailable
            ? withdrawalAtmDescription ||
              getFormattedPayments(
                formatMessage,
                withdrawalAtmPercent,
                withdrawalAtmAbsolute,
                withdrawalAtmMin
              )
            : formatMessage({ id: 'MenuItems.params.serviceUnavailable' }),
          withdrawalForeignAtm: withdrawalForeignAtmAvailable
            ? withdrawalForeignAtmDescription ||
              getFormattedPayments(
                formatMessage,
                withdrawalForeignAtmPercent,
                withdrawalForeignAtmAbsolute,
                withdrawalForeignAtmMin
              )
            : formatMessage({ id: 'MenuItems.params.serviceUnavailable' }),
        };
      },
    },
    {
      name: formatMessage({ id: 'MenuItems.currencyOperations' }),
      isActive: false,
      getParams: ({
        foreignCurrencyBuyDescription,
        foreignCurrencyBuyPercent,
        foreignCurrencyBuyMin,
        foreignCurrencySellDescription,
        foreignCurrencySellPercent,
        foreignCurrencySellMin,
        foreignCurrencyExchangeAvailable,
        foreignCurrencyDescription,
        foreignCurrencyExchangePercent,
        foreignCurrencyExchangeMin,
        swiftAvailable,
        swiftDescription,
        swiftCurrency,
        swiftPercent,
        swiftAbsolute,
        swiftMin,
      }) => {
        return {
          foreignCurrencyBuy:
            foreignCurrencyBuyDescription ||
            getFormattedPayments(
              formatMessage,
              foreignCurrencyBuyPercent,
              null,
              foreignCurrencyBuyMin
            ),
          foreignCurrencySell:
            foreignCurrencySellDescription ||
            getFormattedPayments(
              formatMessage,
              foreignCurrencySellPercent,
              null,
              foreignCurrencySellMin
            ),
          foreignCurrencyExchange: foreignCurrencyExchangeAvailable
            ? foreignCurrencyDescription ||
              getFormattedPayments(
                formatMessage,
                foreignCurrencyExchangePercent,
                null,
                foreignCurrencyExchangeMin
              )
            : formatMessage({ id: 'MenuItems.params.serviceUnavailable' }),
          swift: swiftAvailable
            ? swiftDescription ||
              getFormattedPayments(
                formatMessage,
                swiftPercent,
                swiftAbsolute,
                swiftMin,
                null,
                swiftCurrency
              )
            : formatMessage({ id: 'MenuItems.params.serviceUnavailable' }),
        };
      },
    },
    {
      name: formatMessage({ id: 'MenuItems.additionalBenefits' }),
      isActive: false,
      getParams: ({
        productCardDebitLinkName,
        productCardDebitLinkUrl,
        productCardCreditLinkName,
        productCardCreditLinkUrl,
        productClientBankName,
        clientBankWebLink,
        clientBankAppIosLink,
        clientBankAppAndroidLink,
        productConciergeServiceDescription,
        productConciergeService,
        productAccountStatementDescription,
        productBusinessCreditsDescription,
        productBusinessDepositsDescription,
        productCashCollectionDescription,
        productBenefits,
      }) => {
        return {
          ...(productCardDebitLinkName
            ? {
                cardDebit:
                  productCardDebitLinkUrl &&
                  productCardDebitLinkUrl.includes(
                    process.env.APP_MINFIN_DOMAIN
                  ) ? (
                    <a
                      target={LINK.blank}
                      href={productCardDebitLinkUrl}
                      rel={LINK.relIsolated}
                    >
                      {productCardDebitLinkName}
                    </a>
                  ) : (
                    <span>{productCardDebitLinkName}</span>
                  ),
              }
            : {}),
          ...(productCardCreditLinkName
            ? {
                cardCredit:
                  productCardCreditLinkUrl &&
                  productCardCreditLinkUrl.includes(
                    process.env.APP_MINFIN_DOMAIN
                  ) ? (
                    <a
                      target={LINK.blank}
                      href={productCardCreditLinkUrl}
                      rel={LINK.relIsolated}
                    >
                      {productCardCreditLinkName}
                    </a>
                  ) : (
                    <span>{productCardCreditLinkName}</span>
                  ),
              }
            : {}),
          internetBanking: [
            clientBankWebLink &&
            clientBankWebLink.includes(process.env.APP_MINFIN_DOMAIN) ? (
              <a
                target={LINK.blank}
                href={clientBankWebLink}
                rel={LINK.relIsolated}
              >
                {productClientBankName ||
                  formatMessage({ id: 'MenuItems.params.site' })}
              </a>
            ) : (
              <span>
                {productClientBankName ||
                  formatMessage({ id: 'MenuItems.params.site' })}
              </span>
            ),
            clientBankAppIosLink &&
            clientBankAppIosLink.includes(process.env.APP_MINFIN_DOMAIN) ? (
              <a
                target={LINK.blank}
                href={clientBankAppIosLink}
                rel={LINK.relIsolated}
              >
                {formatMessage({ id: 'MenuItems.params.appStore' })}
              </a>
            ) : (
              <span>{formatMessage({ id: 'MenuItems.params.appStore' })}</span>
            ),
            clientBankAppAndroidLink &&
            clientBankAppAndroidLink.includes(process.env.APP_MINFIN_DOMAIN) ? (
              <a
                target={LINK.blank}
                href={clientBankAppAndroidLink}
                rel={LINK.relIsolated}
              >
                {formatMessage({ id: 'MenuItems.params.googlePlay' })}
              </a>
            ) : (
              <span>
                {formatMessage({ id: 'MenuItems.params.googlePlay' })}
              </span>
            ),
          ].filter(el => !!el),
          conciergeService:
            productConciergeServiceDescription ||
            (productConciergeService
              ? formatMessage({ id: 'MenuItems.params.available' })
              : formatMessage({ id: 'MenuItems.params.none' })),
          ...(productAccountStatementDescription
            ? { accountStatement: productAccountStatementDescription }
            : {}),
          businessCredits:
            productBusinessCreditsDescription ||
            formatMessage({ id: 'MenuItems.params.none' }),
          businessDeposits:
            productBusinessDepositsDescription ||
            formatMessage({ id: 'MenuItems.params.none' }),
          cashCollection:
            productCashCollectionDescription ||
            formatMessage({ id: 'MenuItems.params.none' }),
          ...(productBenefits ? { benefits: productBenefits } : {}),
        };
      },
    },
  ];

  return menu;
};

export const getMoreInfo = formatMessage => {
  const moreInfo = [
    {
      name: MORE_INFO_NAMES.payments,
      getValue: ({ paymentPercent, paymentAbsolute, paymentMin }) => {
        return getFormattedPayments(
          formatMessage,
          paymentPercent,
          paymentAbsolute,
          paymentMin
        );
      },
      hint: ({ paymentDescription }) =>
        !!paymentDescription && paymentDescription,
    },
    {
      name: MORE_INFO_NAMES.service,
      getValue: ({ monthlyFee }) => {
        const fee = monthlyFee
          ? `${monthlyFee} ${formatMessage({ id: 'MenuItems.params.UAH' })}`
          : formatMessage({ id: 'MenuItems.params.free' });
        return fee;
      },
      hint: ({ monthlyFeeDescription }) =>
        !!monthlyFeeDescription && monthlyFeeDescription,
    },
    {
      name: MORE_INFO_NAMES.cashWithdrawal,
      getValue: ({
        withdrawalAtmPercent,
        withdrawalAtmAbsolute,
        withdrawalAtmMin,
      }) => {
        return getFormattedPayments(
          formatMessage,
          withdrawalAtmPercent,
          withdrawalAtmAbsolute,
          withdrawalAtmMin
        );
      },
      hint: ({ withdrawalAtmDescription }) =>
        !!withdrawalAtmDescription && withdrawalAtmDescription,
    },
    {
      name: MORE_INFO_NAMES.cashDeposit,
      getValue: ({
        proceedsEnrollmentCashboxPercent,
        proceedsEnrollmentCashboxAbsolute,
        proceedsEnrollmentCashboxMin,
      }) => {
        return getFormattedPayments(
          formatMessage,
          proceedsEnrollmentCashboxPercent,
          proceedsEnrollmentCashboxAbsolute,
          proceedsEnrollmentCashboxMin
        );
      },
      hint: ({ proceedsEnrollmentCashboxDescription }) =>
        !!proceedsEnrollmentCashboxDescription &&
        proceedsEnrollmentCashboxDescription,
    },
  ];

  return moreInfo;
};

export const getBenefits = (formatMessage, card) => {
  const benefits = [
    card.clientBankName &&
      formatMessage({ id: 'BankCardItem.moreInfoBlock.benefits.clientBank' }),
    card.productAccountOpeningRemote &&
      formatMessage({
        id: 'BankCardItem.moreInfoBlock.benefits.accountOpeningRemote',
      }),
    card.productConciergeService &&
      formatMessage({
        id: 'BankCardItem.moreInfoBlock.benefits.conciergeService',
      }),
    card.tradeAcquiringAvailable &&
      formatMessage({
        id: 'BankCardItem.moreInfoBlock.benefits.tradeAcquiring',
      }),
    card.internetAcquiringAvailable &&
      formatMessage({
        id: 'BankCardItem.moreInfoBlock.benefits.internetAcquiring',
      }),
  ]
    .filter(el => !!el)
    .slice(0, 2);

  return benefits;
};
