export const LOCALE = {
  ru: 'ru',
  uk: 'uk',
};

export const SCROLL_INTO_VIEW = {
  start: 'start',
  smooth: 'smooth',
};

export const LINK = {
  blank: '_blank',
  relIsolated: 'noopener noreferrer nofollow noindex',
};

export const MORE_INFO_NAMES = {
  payments: 'payments',
  service: 'service',
  cashWithdrawal: 'cashWithdrawal',
  cashDeposit: 'cashDeposit',
};
