import React from 'react';
import { hydrate } from 'react-dom';
import { loadableReady } from '@loadable/component';
import 'focus-visible';

import Root from 'components/Root';

import configureStore from 'store';
import { DEFAULT_LOCALE } from 'utils';

const initialState = window.APP_INITIAL_STATE || {};
const translationsLocale = window.APP_TRANSLATIONS_LOCALE || DEFAULT_LOCALE;
const routerBasename =
  window.APP_ROUTER_BASENAME || process.env.APP_ROUTER_BASENAME;

delete window.APP_INITIAL_STATE;
delete window.APP_TRANSLATIONS_LOCALE;
delete window.APP_ROUTER_BASENAME;
delete window.APP_TEMPLATE_PARAMS;

const store = configureStore(initialState);

const render = Component => {
  hydrate(
    <Component
      store={store}
      locale={translationsLocale}
      routerBasename={routerBasename}
      env="client"
    />,
    document.getElementById('root')
  );
};

loadableReady(() => {
  if (module.hot) {
    module.hot.accept('components/Root', () => {
      const Component = require('components/Root').default; //eslint-disable-line

      render(Component);
    });
  }

  render(Root);
});
