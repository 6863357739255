import { createSelector } from 'reselect';

export const getMainCurrencies = state => state.currencies.mainCurrencies;

export const getCurrenciesOptions = createSelector(
  getMainCurrencies,
  currencies => {
    return currencies.reduce((res, currency) => {
      return { ...res, [currency]: { label: currency, value: currency } };
    }, {});
  }
);
