import { schema } from 'normalizr';

// --------- GET DATA FOR PRODUCTS --------- //

export const product = new schema.Entity(
  'products',
  {},
  {
    idAttribute: ({ id }) => parseInt(id, 10),
    processStrategy: ({
      id,
      vendorId,
      productId,
      productName,
      productSlug,
      accountClosingFee,
      addAccountOpeningFee,
      additionalCurrencies,
      annualFee,
      clientBankAppAndroidLink,
      clientBankAppIosLink,
      clientBankName,
      clientBankWebLink,
      currency,
      enrollmentCardAbsolute,
      enrollmentCardDescription,
      enrollmentCardMax,
      enrollmentCardMin,
      enrollmentCardPercent,
      enrollmentCashAbsolute,
      enrollmentCashDescription,
      enrollmentCashMax,
      enrollmentCashMin,
      enrollmentCashPercent,
      enrollmentCashlessAbsolute,
      enrollmentCashlessAvailable,
      enrollmentCashlessDescription,
      enrollmentCashlessMax,
      enrollmentCashlessMin,
      enrollmentCashlessPercent,
      enrollmentInternetAcquiringAbsolute,
      enrollmentInternetAcquiringDescription,
      enrollmentInternetAcquiringMin,
      enrollmentInternetAcquiringPercent,
      enrollmentTradeAcquiringAbsolute,
      enrollmentTradeAcquiringDescription,
      enrollmentTradeAcquiringMin,
      enrollmentTradeAcquiringPercent,
      firstAccountOpeningFee,
      foreignCurrencyBuyDescription,
      foreignCurrencyBuyMin,
      foreignCurrencyBuyPercent,
      foreignCurrencyDescription,
      foreignCurrencyExchangeAvailable,
      foreignCurrencyExchangeMin,
      foreignCurrencyExchangePercent,
      foreignCurrencySellDescription,
      foreignCurrencySellPercent,
      internetAcquiringAvailable,
      monthlyFee,
      monthlyFeeDescription,
      paymentAbsolute,
      paymentAdditionalFee,
      paymentDescription,
      paymentInternalAbsolute,
      paymentInternalDescription,
      paymentInternalMin,
      paymentInternalPercent,
      paymentMin,
      paymentPercent,
      paymentSelfDescription,
      paymentSelfMin,
      paymentSelfPercent,
      proceedsEnrollmentCashboxAbsolute,
      proceedsEnrollmentCashboxAvailable,
      proceedsEnrollmentCashboxDescription,
      proceedsEnrollmentCashboxMin,
      proceedsEnrollmentCashboxPercent,
      proceedsEnrollmentTerminalAbsolute,
      proceedsEnrollmentTerminalAvailable,
      proceedsEnrollmentTerminalDescription,
      proceedsEnrollmentTerminalMin,
      proceedsEnrollmentTerminalPercent,
      productAccountOpeningDescription,
      productAccountOpeningRemote,
      productAccountStatementDescription,
      productBenefits,
      productBusinessCreditsDescription,
      productBusinessDepositsDescription,
      productCardCreditLinkName,
      productCardCreditLinkUrl,
      productCardDebitLinkName,
      productCardDebitLinkUrl,
      productCashCollectionDescription,
      productClientActivityType,
      productClientType,
      productConciergeService,
      productConciergeServiceDescription,
      productSalaryProjectDescription,
      selfPaymentSelfAbsolute,
      swiftAbsolute,
      swiftAvailable,
      swiftCurrency,
      swiftDescription,
      swiftMax,
      swiftMin,
      swiftPercent,
      tariffBankDescription,
      trackingUrl,
      tradeAcquiringAvailable,
      withdrawalAtmAbsolute,
      withdrawalAtmAvailable,
      withdrawalAtmDescription,
      withdrawalAtmMin,
      withdrawalAtmPercent,
      withdrawalCashboxAbsolute,
      withdrawalCashboxDescription,
      withdrawalCashboxMin,
      withdrawalCashboxPercent,
      withdrawalForeignAtmAbsolute,
      withdrawalForeignAtmAvailable,
      withdrawalForeignAtmDescription,
      withdrawalForeignAtmMin,
      withdrawalForeignAtmPercent,
      withdrawalForeignCashboxAbsolute,
      withdrawalForeignCashboxAvailable,
      withdrawalForeignCashboxDescription,
      withdrawalForeignCashboxMin,
      withdrawalForeignCashboxPercent,
      productAdvantages,
    }) => ({
      id: parseInt(id, 10) || 0,
      vendorId: parseInt(vendorId, 10) || 0,
      productId,
      productName,
      productSlug,
      advantages: productAdvantages,
      accountClosingFee,
      addAccountOpeningFee,
      additionalCurrencies: Array.isArray(additionalCurrencies)
        ? additionalCurrencies
        : [],
      annualFee,
      clientBankAppAndroidLink,
      clientBankAppIosLink,
      clientBankName,
      clientBankWebLink,
      currency,
      enrollmentCardAbsolute,
      enrollmentCardDescription,
      enrollmentCardMax,
      enrollmentCardMin,
      enrollmentCardPercent,
      enrollmentCashAbsolute,
      enrollmentCashDescription,
      enrollmentCashMax,
      enrollmentCashMin,
      enrollmentCashPercent,
      enrollmentCashlessAbsolute,
      enrollmentCashlessAvailable: Boolean(enrollmentCashlessAvailable),
      enrollmentCashlessDescription,
      enrollmentCashlessMax,
      enrollmentCashlessMin,
      enrollmentCashlessPercent,
      enrollmentInternetAcquiringAbsolute,
      enrollmentInternetAcquiringDescription,
      enrollmentInternetAcquiringMin,
      enrollmentInternetAcquiringPercent,
      enrollmentTradeAcquiringAbsolute,
      enrollmentTradeAcquiringDescription,
      enrollmentTradeAcquiringMin,
      enrollmentTradeAcquiringPercent,
      firstAccountOpeningFee,
      foreignCurrencyBuyDescription,
      foreignCurrencyBuyMin,
      foreignCurrencyBuyPercent,
      foreignCurrencyDescription,
      foreignCurrencyExchangeAvailable: Boolean(
        foreignCurrencyExchangeAvailable
      ),
      foreignCurrencyExchangeMin,
      foreignCurrencyExchangePercent,
      foreignCurrencySellDescription,
      foreignCurrencySellPercent,
      internetAcquiringAvailable: Boolean(internetAcquiringAvailable),
      monthlyFee,
      monthlyFeeDescription,
      paymentAbsolute,
      paymentAdditionalFee,
      paymentDescription,
      paymentInternalAbsolute,
      paymentInternalDescription,
      paymentInternalMin,
      paymentInternalPercent,
      paymentMin,
      paymentPercent,
      paymentSelfDescription,
      paymentSelfMin,
      paymentSelfPercent,
      proceedsEnrollmentCashboxAbsolute,
      proceedsEnrollmentCashboxAvailable: Boolean(
        proceedsEnrollmentCashboxAvailable
      ),
      proceedsEnrollmentCashboxDescription,
      proceedsEnrollmentCashboxMin,
      proceedsEnrollmentCashboxPercent,
      proceedsEnrollmentTerminalAbsolute,
      proceedsEnrollmentTerminalAvailable: Boolean(
        proceedsEnrollmentTerminalAvailable
      ),
      proceedsEnrollmentTerminalDescription,
      proceedsEnrollmentTerminalMin,
      proceedsEnrollmentTerminalPercent,
      productAccountOpeningDescription,
      productAccountOpeningRemote,
      productAccountStatementDescription,
      productBenefits,
      productBusinessCreditsDescription,
      productBusinessDepositsDescription,
      productCardCreditLinkName,
      productCardCreditLinkUrl,
      productCardDebitLinkName,
      productCardDebitLinkUrl,
      productCashCollectionDescription,
      productClientActivityType: Array.isArray(productClientActivityType)
        ? productClientActivityType
        : [],
      productClientType: Array.isArray(productClientType)
        ? productClientType
        : [],
      productConciergeService,
      productConciergeServiceDescription,
      productSalaryProjectDescription,
      selfPaymentSelfAbsolute,
      swiftAbsolute,
      swiftAvailable: Boolean(swiftAvailable),
      swiftCurrency: Array.isArray(swiftCurrency) ? swiftCurrency : [],
      swiftDescription,
      swiftMax,
      swiftMin,
      swiftPercent,
      tariffBankDescription,
      trackingUrl,
      tradeAcquiringAvailable: Boolean(tradeAcquiringAvailable),
      withdrawalAtmAbsolute,
      withdrawalAtmAvailable: Boolean(withdrawalAtmAvailable),
      withdrawalAtmDescription,
      withdrawalAtmMin,
      withdrawalAtmPercent,
      withdrawalCashboxAbsolute,
      withdrawalCashboxDescription,
      withdrawalCashboxMin,
      withdrawalCashboxPercent,
      withdrawalForeignAtmAbsolute,
      withdrawalForeignAtmAvailable: Boolean(withdrawalForeignAtmAvailable),
      withdrawalForeignAtmDescription,
      withdrawalForeignAtmMin,
      withdrawalForeignAtmPercent,
      withdrawalForeignCashboxAbsolute,
      withdrawalForeignCashboxAvailable: Boolean(
        withdrawalForeignCashboxAvailable
      ),
      withdrawalForeignCashboxDescription,
      withdrawalForeignCashboxMin,
      withdrawalForeignCashboxPercent,
    }),
  }
);

export const products = [product];
