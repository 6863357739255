import loadable from '@loadable/component';

import {
  fetchOrganizations,
  fetchOrganization,
  fetchSeoMeta,
  fetchSeoLinksBlocks,
  fetchSeoTextsBlocks,
  fetchProducts,
  fetchUpdatedDate,
} from 'actions';

const HomePage = loadable(() => import('components/HomePage'));
const OrganizationPage = loadable(() => import('components/OrganizationPage'));
const NotFoundPage = loadable(() => import('components/NotFoundPage'));

const routes = [
  {
    id: 'homePage',
    path: ['/:clientType(it)?/:page(fop)?/'],
    exact: true,
    strict: true,
    component: HomePage,
    props: {
      loadData: async (dispatch, props) => {
        return Promise.all([
          dispatch(fetchUpdatedDate(props)),
          dispatch(fetchProducts(props)),
          dispatch(fetchOrganizations(props)),
          dispatch(fetchSeoMeta(props)),
          dispatch(fetchSeoLinksBlocks(props)),
          dispatch(fetchSeoTextsBlocks(props)),
        ]);
      },
    },
  },
  {
    id: 'organizationPage',
    path: ['/:organizationSlug([a-z0-9_-]{1,})/'],
    exact: true,
    strict: true,
    component: OrganizationPage,
    props: {
      loadData: async (dispatch, props) => {
        return Promise.all([
          ...[
            Promise.all([dispatch(fetchOrganization(props))]).then(() =>
              dispatch(fetchProducts(props))
            ),
          ],
          dispatch(fetchSeoMeta(props)),
          dispatch(fetchSeoLinksBlocks(props)),
          dispatch(fetchSeoTextsBlocks(props)),
        ]);
      },
    },
  },
  {
    id: 'notFoundPage',
    props: {},
    component: NotFoundPage,
  },
];

export default routes;
