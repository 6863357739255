import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: #f2f5f8;
  margin: 0 -10px;

  @media (min-width: 801px) {
    margin: 0 -20px;
  }
`;

export default Wrapper;
