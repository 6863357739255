import axios from 'axios';
import { camelizeKeys } from 'humps';

export const request = async (url, { method = 'get', data } = {}) => {
  try {
    const response = await axios({
      method,
      url,
      data: !data || Object.keys(data).length === 0 ? null : data,
    });

    return camelizeKeys(response.data);
  } catch (error) {
    console.error('request error: ', url);
    throw error;
  }
};

export default request;
