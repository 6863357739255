import { normalize } from 'normalizr';

import { request } from 'utils';

import {
  FETCH_FINANCE_ORGANIZATIONS_REQUEST,
  FETCH_FINANCE_ORGANIZATIONS_SUCCESS,
  FETCH_FINANCE_ORGANIZATIONS_FAILURE,
  FETCH_FINANCE_ORGANIZATION_REQUEST,
  FETCH_FINANCE_ORGANIZATION_SUCCESS,
  FETCH_FINANCE_ORGANIZATION_FAILURE,
} from 'actionTypes';

import {
  getOrganizationsIsFetching,
  getOrganizationsIsLoaded,
  makeGetOrganization,
} from 'selectors';

import { organizations as organizationsSchema } from 'schemas';
import { PRODUCT_TYPE } from '../constants';

const getOrganization = makeGetOrganization();

const getShouldFetchOrganizations = (state, props) => {
  const isFetching = getOrganizationsIsFetching(state, props);
  const isLoaded = getOrganizationsIsLoaded(state, props);

  return !isFetching && !isLoaded;
};

export const fetchOrganizations = props => async (dispatch, getState) => {
  try {
    const state = getState();

    if (!getShouldFetchOrganizations(state, props)) {
      return true;
    }

    dispatch({
      type: FETCH_FINANCE_ORGANIZATIONS_REQUEST,
      payload: {},
    });

    const requestURL = `${process.env.APP_COMPANIES_API_ROOT}?locale=${props.intl.locale}&type[]=bank`;
    const { data } = await request(requestURL);

    const url = `${process.env.APP_ORGANISATIONS_API_ROOT}?active=1&product_type=${PRODUCT_TYPE.rko}`;

    let fullOrganizationsData = [];
    try {
      const { data: organizationsList } = await request(url);

      fullOrganizationsData = data.map(item => {
        const index = organizationsList.findIndex(
          organization => Number(item.id) === Number(organization.minfinId)
        );

        if (index !== -1) {
          return {
            ...item,
            rkoCommercialOrderMinfin:
              organizationsList[index].rkoCommercialOrderMinfin,
          };
        }
        return item;
      });
    } catch (error) {
      fullOrganizationsData = [...data];
      console.log(`error occurred while getting data from ${url}`);
    }

    const {
      entities: { organizations: entities = {} },
      result: ids = [],
    } = normalize(fullOrganizationsData, organizationsSchema);

    return dispatch({
      type: FETCH_FINANCE_ORGANIZATIONS_SUCCESS,
      payload: {
        entities,
        ids,
      },
    });
  } catch (error) {
    return dispatch({
      type: FETCH_FINANCE_ORGANIZATIONS_FAILURE,
      payload: {},
    });
  }
};

const getShouldFetchOrganization = (state, props) => {
  const { isFetching, isLoaded } = getOrganization(state, props);

  return !isFetching && !isLoaded;
};

export const fetchOrganization = props => async (dispatch, getState) => {
  try {
    const state = getState();

    if (!getShouldFetchOrganization(state, props)) {
      return true;
    }

    const { organizationSlug } = props.params;

    dispatch({
      type: FETCH_FINANCE_ORGANIZATION_REQUEST,
      payload: { organizationSlug, isFetching: true },
    });

    const requestURL = `${process.env.APP_COMPANIES_API_V2_ROOT}${organizationSlug}/?locale=${props.intl.locale}`;

    const { data } = await request(requestURL);

    return dispatch({
      type: FETCH_FINANCE_ORGANIZATION_SUCCESS,
      payload: {
        organizationSlug,
        data: {
          id: parseInt(data.financeId, 10) || 0,
          minfinId: parseInt(data.id, 10) || 0,
          slug: data.slug.toLowerCase(),
          name: data.name,
          nameG: data.nameG,
          nameP: data.nameP,
          fullLogo: data.fullLogo,
          menu: data.menu,
          backgroundImage: data.backgroundImage,
        },
      },
    });
  } catch (error) {
    const { organizationSlug } = props.params;

    return dispatch({
      type: FETCH_FINANCE_ORGANIZATION_FAILURE,
      payload: { organizationSlug },
    });
  }
};
