export const FETCH_FINANCE_ORGANIZATIONS_REQUEST =
  'FETCH_FINANCE_ORGANIZATIONS_REQUEST';
export const FETCH_FINANCE_ORGANIZATIONS_SUCCESS =
  'FETCH_FINANCE_ORGANIZATIONS_SUCCESS';
export const FETCH_FINANCE_ORGANIZATIONS_FAILURE =
  'FETCH_FINANCE_ORGANIZATIONS_FAILURE';

export const FETCH_FINANCE_ORGANIZATION_REQUEST =
  'FETCH_FINANCE_ORGANIZATION_REQUEST';
export const FETCH_FINANCE_ORGANIZATION_SUCCESS =
  'FETCH_FINANCE_ORGANIZATION_SUCCESS';
export const FETCH_FINANCE_ORGANIZATION_FAILURE =
  'FETCH_FINANCE_ORGANIZATION_FAILURE';
