export const PRODUCT_TYPE = {
  rko: 11,
};

export const PRODUCT_TYPES = {
  all: 'all',
  fop: 'fop',
  jur: 'jur',
};

export const CLIENT_TYPES = {
  it: 'it',
};

export const ACTIVITY_TYPE_KEYS = {
  it: 'J',
};

export const CheckboxFilters = {
  platforms: {
    mobileBanking: {
      getFiltred: arr =>
        arr.filter(
          el => !!el?.clientBankAppIosLink || !!el?.clientBankAppAndroidLink
        ),
    },
    internetBanking: {
      getFiltred: arr => arr.filter(el => !!el?.clientBankWebLink),
    },
  },
  finance: {
    deposits: {
      getFiltred: arr =>
        arr.filter(el => !!el?.productBusinessDepositsDescription),
    },
    credits: {
      getFiltred: arr =>
        arr.filter(el => !!el?.productBusinessCreditsDescription),
    },
  },
  account: {
    remoteOpening: {
      getFiltred: arr => arr.filter(el => !!el?.productAccountOpeningRemote),
    },
    salaryProject: {
      getFiltred: arr =>
        arr.filter(el => !!el?.productSalaryProjectDescription),
    },
  },
  acquiring: {
    internetAcquiring: {
      getFiltred: arr => arr.filter(el => !!el?.internetAcquiringAvailable),
    },
    tradeAcquiring: {
      getFiltred: arr => arr.filter(el => !!el?.tradeAcquiringAvailable),
    },
  },
  additionally: {
    cashCollection: {
      getFiltred: arr =>
        arr.filter(el => !!el?.productCashCollectionDescription),
    },
    conciergeService: {
      getFiltred: arr => arr.filter(el => !!el?.productConciergeService),
    },
  },
};

export const MonthlyFee = {
  monthlyFee: 'free',
};

export const MaintenanceCost = {
  zero: {
    value: 'free',
    getFiltred: arr => arr.filter(el => el?.monthlyFee === 0),
  },
};

export const CURRENCIES = {
  uah: 'UAH',
  usd: 'USD',
  eur: 'EUR',
};

export const CURRENCIES_SYMBOLS = {
  uah: '₴',
  usd: '$',
  eur: '€',
};

export const ACTIVITY_TYPE = {
  A: {
    label: 'Сельское хозяйство, лесное хозяйство и рыбное хозяйство',
    value: 'A',
  },
  B: {
    label: 'Добывающая промышленность и разработка карьеров',
    value: 'B',
  },
  C: {
    label: 'Перерабатывающая промышленность',
    value: 'C',
  },
  D: {
    label: 'Поставка электроэнергии, газа, пара и кондиционированного воздуха',
    value: 'D',
  },
  E: {
    label: 'Водоснабжение; канализация, обращение с отходами',
    value: 'E',
  },
  F: {
    label: 'Строительство',
    value: 'F',
  },
  G: {
    label:
      'Оптовая и розничная торговля; ремонт автотранспортных средств и мотоциклов',
    value: 'G',
  },
  H: {
    label: 'Транспорт, складское хозяйство, почтовая и курьерская деятельность',
    value: 'H',
  },
  I: {
    label: 'Временное размещение и организация питания',
    value: 'I',
  },
  J: {
    label: 'Информация и телекоммуникации',
    value: 'J',
  },
  K: {
    label: 'Финансовая и страховая деятельность',
    value: 'K',
  },
  L: {
    label: 'Операции с недвижимым имуществом',
    value: 'L',
  },
  M: {
    label: 'Профессиональная, научная и техническая деятельность',
    value: 'M',
  },
  N: {
    label:
      'Деятельность в сфере административного и вспомогательного обслуживания',
    value: 'N',
  },
  O: {
    label:
      'Государственное управление и оборона; обязательное социальное страхование',
    value: 'O',
  },
  P: {
    label: 'Образование',
    value: 'P',
  },
  Q: {
    label: 'Здравоохранение и оказание социальной помощи',
    value: 'Q',
  },
  R: {
    label: 'Искусство, спорт, развлечения и отдых',
    value: 'R',
  },
  S: {
    label: 'Предоставление других видов услуг',
    value: 'S',
  },
  T: {
    label: 'Деятельность домашних хозяйств',
    value: 'T',
  },
  U: {
    label: 'Деятельность экстерриториальных организаций и органов',
    value: 'U',
  },
};
