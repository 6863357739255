import { createSelector } from 'reselect';
import { parse, stringify } from 'query-string';

import {
  CheckboxFilters,
  ACTIVITY_TYPE,
  CLIENT_TYPES,
} from 'constants/filters';

const getStringByData = (data, currentKey) => {
  if (data?.length) {
    return data.reduce((res, cur, index) => {
      return `${res}${currentKey}[]=${cur}${
        index !== data.length - 1 ? '&' : ''
      }`;
    }, '');
  }

  return '';
};

// After adding keys, check ("actions/products" --> fetchProductsIds) if you need to exclude them from the request parameters
export const routerStateValuesConfig = {
  organizationSlug: {
    name: 'organizationSlug',
    urlPart: () => 'path',
    type: 'params',
    getFormattedValue: value => {
      return value || '';
    },
    getProductsAPIFilters: (filterValue, { organization }) => {
      return !!organization && `vendor_id=${organization?.id}`;
    },
    getQuery: value => value || '',
  },
  page: {
    name: 'page',
    urlPart: () => 'path',
    type: 'path',
    getFormattedValue: value => {
      return value || '';
    },
    getProductsAPIFilters: (filterValue, { page }) =>
      !!page && `product_client_type[]=${page}`,
    getQuery: value => value || '',
  },
  clientType: {
    name: 'clientType',
    urlPart: () => 'path',
    type: 'params',
    getFormattedValue: value => {
      return value || '';
    },
    getProductsAPIFilters: (filterValue, { clientType }) =>
      !!clientType &&
      clientType === CLIENT_TYPES.it &&
      `product_client_activity_type[]=j`,
    getQuery: value => value || '',
  },
  monthlyFee: {
    name: 'monthlyFee',
    urlPart: () => 'search',
    type: 'search',
    getFormattedValue: value => {
      return value || '';
    },
    getProductsAPIFilters: (filterValue, { monthlyFee = [] }) => {
      return monthlyFee.includes('free') && `monthly_fee=0`;
    },
    getQuery: value => value || '',
  },
  currency: {
    name: 'currency',
    urlPart: () => 'search',
    type: 'select',
    getFormattedValue: value => {
      return value || '';
    },
    getProductsAPIFilters: (filterValue, { currency }) =>
      !!currency && `currency=${currency.toUpperCase()}`,
    getQuery: value => value || '',
  },
  activityType: {
    name: 'activityType',
    urlPart: () => 'search',
    type: 'multiSelect',
    getFormattedValue: value => value || [],
    getQuery: value =>
      value.filter(el => Object.keys(ACTIVITY_TYPE).includes(el)) || [],
    getProductsAPIFilters: (filterValue, { activityType }) =>
      !!activityType &&
      filterValue !== CLIENT_TYPES.it &&
      activityType.reduce((res, type) => {
        return `${
          res ? `${res}&` : ''
        }${`product_client_activity_type[]=${type.toLowerCase()}`}`;
      }, ''),
  },
  platforms: {
    name: 'platforms',
    urlPart: () => 'search',
    type: 'checkbox',
    getFormattedValue: value => value || [],
    getQuery: value =>
      value
        ?.split(',')
        .filter(el => Object.keys(CheckboxFilters.platforms).includes(el)) ||
      [],
    getProductsAPIFilters: filterValue =>
      getStringByData(filterValue, 'platforms'),
  },
  finance: {
    name: 'finance',
    urlPart: () => 'search',
    type: 'checkbox',
    getFormattedValue: value => value || [],
    getQuery: value =>
      value
        ?.split(',')
        .filter(el => Object.keys(CheckboxFilters.finance).includes(el)) || [],
    getProductsAPIFilters: filterValue =>
      getStringByData(filterValue, 'finance'),
  },
  account: {
    name: 'account',
    urlPart: () => 'search',
    type: 'checkbox',
    getFormattedValue: value => value || [],
    getQuery: value =>
      value
        ?.split(',')
        .filter(el => Object.keys(CheckboxFilters.account).includes(el)) || [],
    getProductsAPIFilters: filterValue =>
      getStringByData(filterValue, 'account'),
  },
  acquiring: {
    name: 'acquiring',
    urlPart: () => 'search',
    type: 'checkbox',
    getFormattedValue: value => value || [],
    getQuery: value =>
      value
        ?.split(',')
        .filter(el => Object.keys(CheckboxFilters.acquiring).includes(el)) ||
      [],
    getProductsAPIFilters: filterValue =>
      getStringByData(filterValue, 'acquiring'),
  },
  additionally: {
    name: 'additionally',
    urlPart: () => 'search',
    type: 'checkbox',
    getFormattedValue: value => value || [],
    getQuery: value =>
      value
        ?.split(',')
        .filter(el => Object.keys(CheckboxFilters.additionally).includes(el)) ||
      [],
    getProductsAPIFilters: filterValue =>
      getStringByData(filterValue, 'additionally'),
  },
};

export const getParsedRouterLocationSearch = createSelector(
  (state, props) => props.location.search,
  routerLocationSearch => parse(routerLocationSearch)
);

export const getFormattedRouterLocationSearch = createSelector(
  getParsedRouterLocationSearch,
  parsedRouterLocationSearch => {
    return Object.keys(routerStateValuesConfig).reduce((result, key) => {
      const stateKeyConfig = routerStateValuesConfig[key];

      if (stateKeyConfig.type !== 'checkbox') {
        return result;
      }

      if (!stateKeyConfig.getQuery(parsedRouterLocationSearch[key]).length)
        return result;

      return {
        ...result,
        [stateKeyConfig.name]: stateKeyConfig.getQuery(
          parsedRouterLocationSearch[key]
        ),
      };
    }, {});
  }
);

const getFormattedRouterParams = routerMatchParams => {
  return Object.keys(routerStateValuesConfig).reduce((result, key) => {
    const stateKeyConfig = routerStateValuesConfig[key];
    if (stateKeyConfig.urlPart() !== 'path') {
      return result;
    }

    return {
      ...result,
      [stateKeyConfig.name]: stateKeyConfig.getFormattedValue(
        routerMatchParams?.[key] || ''
      ),
    };
  }, {});
};

export const getFormattedRouterMatchParams = createSelector(
  (state, props) => props.params,
  routerMatchParams => getFormattedRouterParams(routerMatchParams)
);

export const makeGetFormattedRouterUrlState = () => {
  return createSelector(
    getFormattedRouterLocationSearch,
    getFormattedRouterMatchParams,
    (formattedRouterLocationSearch, formattedRouterMatchParams) => ({
      ...formattedRouterLocationSearch,
      ...formattedRouterMatchParams,
    })
  );
};

export const getFormattedRouterUrlState = makeGetFormattedRouterUrlState();

export const getSearchParams = createSelector(
  (state, props) => props.location.search,
  search => search
);

export const getParsedSearchParams = createSelector(
  (state, props) => props.location.search,
  search => parse(search, { arrayFormat: 'comma' })
);

export const getFormattedSearchParams = createSelector(
  getParsedSearchParams,
  searchParams =>
    Object.keys(searchParams).reduce((res, key) => {
      if (Array.isArray(searchParams[key])) {
        return { ...res, [key]: searchParams[key] };
      }

      return { ...res, [key]: [searchParams[key]] };
    }, {})
);

export const getDynamicLocationSearch = ({ search }, value, options) => {
  const newLocationSearchObject = {
    ...parse(search),
    ...Object.keys(value).reduce((result, key) => {
      const { getFormattedValue, name } = routerStateValuesConfig[key];

      const formattedValue = getFormattedValue(value[key], options);

      return {
        ...result,
        [name]: formattedValue,
      };
    }, {}),
  };

  const newLocationSearchValuesString = stringify(
    Object.keys(newLocationSearchObject).reduce((result, key) => {
      if (!newLocationSearchObject[key]) {
        return result;
      }

      return {
        ...result,
        [key]: newLocationSearchObject[key],
      };
    }, {}),
    { arrayFormat: 'comma' }
  );

  return newLocationSearchValuesString.length
    ? `?${newLocationSearchValuesString}`
    : '';
};
