import {
  FETCH_PRODUCTS_REQUEST,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_FAILURE,
} from 'actionTypes';

import createReducer from './createReducer';

const initialState = {};

const products = createReducer(initialState, {
  [FETCH_PRODUCTS_REQUEST]: (state, action) => ({
    ...state,
    [action.payload.url]: {
      ...action.payload,
      isFetching: true,
      isLoaded: false,
      isRequestFailed: false,
    },
  }),

  [FETCH_PRODUCTS_SUCCESS]: (state, action) => ({
    ...state,
    [action.payload.url]: {
      ...action.payload,
      entities: action.payload.entities,
      isFetching: false,
      isLoaded: true,
      isRequestFailed: false,
    },
  }),

  [FETCH_PRODUCTS_FAILURE]: (state, action) => ({
    ...state,
    [action.payload.url]: {
      ...action.payload,
      isFetching: false,
      isLoaded: false,
      isRequestFailed: true,
    },
  }),
});

export default products;
