import { createSelector } from 'reselect';

const getDate = state => state.updatedDate;

export const getUpdatedDateFetching = createSelector(
  getDate,
  updatedDate => updatedDate.isFetching
);

export const getUpdatedDateLoaded = createSelector(
  getDate,
  updatedDate => updatedDate.isLoaded
);

export const getUpdatedDate = createSelector(getDate, ({ date }) => date);
