import { request } from 'utils';

import {
  FETCH_UPDATE_DATE_REQUEST,
  FETCH_UPDATE_DATE_FAILURE,
  FETCH_UPDATE_DATE_SUCCESS,
} from 'actionTypes';

import { getUpdatedDateFetching, getUpdatedDateLoaded } from 'selectors';

const getShouldFetchUpdateDate = (state, props) => {
  const isFetching = getUpdatedDateFetching(state, props);
  const isLoaded = getUpdatedDateLoaded(state, props);

  return !isFetching && !isLoaded;
};

export const fetchUpdatedDate = props => async (dispatch, getState) => {
  try {
    const state = getState();

    if (!getShouldFetchUpdateDate(state, props)) {
      return true;
    }

    dispatch({
      type: FETCH_UPDATE_DATE_REQUEST,
      payload: {},
    });

    const date = await request(
      `${process.env.APP_PRODUCTS_API_ROOT}date?product_type=11`
    );

    return dispatch({
      type: FETCH_UPDATE_DATE_SUCCESS,
      payload: { date: date.updatedDate },
    });
  } catch (error) {
    return dispatch({
      type: FETCH_UPDATE_DATE_FAILURE,
      payload: {},
    });
  }
};

export default fetchUpdatedDate();
