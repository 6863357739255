export const getDimensions = target => {
  if (typeof target === 'string') {
    return document.getElementById(target).getBoundingClientRect();
  }

  return target.getBoundingClientRect();
};

export const closest = (target, parent) => {
  if (!target) return false;
  return parent === target || closest(target.parentNode, parent);
};

export const addResizeEndListener = (element, callback) => {
  if ('ResizeObserver' in window) {
    const resizeObserver = new ResizeObserver(callback);
    resizeObserver.observe(element);

    return resizeObserver;
  }

  if (!('ResizeObserver' in window)) {
    let resizeTimeout = null;
    const resizeHandler = () => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(callback, 400);
    };

    window.addEventListener('resize', resizeHandler);

    return resizeHandler;
  }

  return null;
};

export const removeResizeEndListener = (element, listener) => {
  if ('ResizeObserver' in window) {
    listener.unobserve(element);
  }

  if (!('ResizeObserver' in window)) {
    window.removeEventListener('resize', listener);
  }
};
