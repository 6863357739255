import { createSelector } from 'reselect';

import { getLocaleUrlPath } from 'utils';

const getSeo = state => state.seo;

export const makeGetSeoUrl = () => {
  return createSelector(
    (state, props) => props.location.pathname,
    (state, props) => props.locale || props.intl.locale,
    (pathname, locale) => {
      return `${getLocaleUrlPath(locale)}${
        process.env.APP_ROUTER_BASENAME
      }${pathname}`;
    }
  );
};

export const makeGetSeoByUrl = () => {
  const getSeoUrl = makeGetSeoUrl();

  return createSelector(getSeo, getSeoUrl, (seo, seoUrl) => {
    return seo[seoUrl] || {};
  });
};

export const makeGetSeoMeta = () =>
  createSelector(makeGetSeoByUrl(), seo => seo.meta || {});

export const getSeoMeta = makeGetSeoMeta();

export const makeGetSeoLinksBlocks = () => {
  const getSeoByUrl = makeGetSeoByUrl();

  return createSelector(getSeoByUrl, seo => seo.linksBlocks || {});
};

export const makeGetSeoLinksBlocksItems = () => {
  const getSeoLinksBlocks = makeGetSeoLinksBlocks();

  return createSelector(
    getSeoLinksBlocks,
    seoLinksBlocks => seoLinksBlocks.items || []
  );
};

export const makeGetSeoTextsBlocks = () => {
  const getSeoByUrl = makeGetSeoByUrl();

  return createSelector(getSeoByUrl, seo => seo.texts || {});
};

export const makeGetSeoTextsBlocksItems = () => {
  const getSeoTextsBlocks = makeGetSeoTextsBlocks();

  return createSelector(
    getSeoTextsBlocks,
    seoTextsBlocks => seoTextsBlocks.items || []
  );
};

const getSeoByUrl = makeGetSeoByUrl();

export const makeGetBreadcrumbs = () =>
  createSelector(getSeoByUrl, seo => seo.breadcrumbs || {});

export const getBreadcrumbs = makeGetBreadcrumbs();
