import { createSelector } from 'reselect';

import { getFormattedRouterMatchParams } from 'selectors/router';

import { getLocaleUrlPath } from 'utils';

import { PRODUCT_TYPES, CLIENT_TYPES } from 'constants/index';

export const getDefaultBreadcrumbs = createSelector(
  getFormattedRouterMatchParams,
  (state, props) => props.intl,
  state => state.organizations,
  (
    { organizationSlug, clientType, page },
    { locale, formatMessage },
    { organization }
  ) => {
    const urlLocale = getLocaleUrlPath(locale);
    const routerBasename = `${urlLocale}${process.env.APP_ROUTER_BASENAME}`;

    const isRvkPage = !!organizationSlug;
    const isFopPage = page === PRODUCT_TYPES.fop && !isRvkPage;
    const isItPage = clientType === CLIENT_TYPES.it;

    const organizationName = organization[organizationSlug]?.data?.nameP;

    const defaultBreadcrumbs = [
      {
        name: formatMessage({ id: 'breadcrumbs.mainPage' }),
        link: urlLocale,
      },
      {
        name: formatMessage({ id: 'breadcrumbs.rkoPage' }),
        link: routerBasename,
      },
      isItPage
        ? {
            name: formatMessage({ id: 'breadcrumbs.itPage' }),
            link: `${routerBasename}/${organizationSlug}it/`,
          }
        : null,
      isFopPage
        ? {
            name: formatMessage({ id: 'breadcrumbs.fopPage' }),
            link: `${routerBasename}/${PRODUCT_TYPES.fop}`,
          }
        : null,
      isRvkPage
        ? {
            name: formatMessage(
              { id: 'breadcrumbs.rvkPage' },
              { bank: organizationName }
            ),
            link: `${routerBasename}/${organizationSlug}`,
          }
        : null,
    ];

    return defaultBreadcrumbs.filter(Boolean);
  }
);
