import { createSelector } from 'reselect';
import { getLocaleUrlPath } from 'utils';

export const makeGetPathname = () =>
  createSelector(
    (state, props) => props.location.pathname,
    (state, props) => props.intl.locale,
    (locationPathname, locale) => {
      return `${getLocaleUrlPath(locale)}${locationPathname}`;
    }
  );

export const getPathname = makeGetPathname();

export const makeGetFullPathname = () => {
  return createSelector(
    (state, props) => props.location.pathname,
    (state, props) => props.locale || props.intl.locale,
    (pathname, locale) => {
      return `${getLocaleUrlPath(locale)}${
        process.env.APP_ROUTER_BASENAME
      }${pathname}`;
    }
  );
};

export const getFullPathname = makeGetFullPathname();
